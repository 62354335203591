import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import './assets/css/Home.css';
import './assets/css/Settings.css';
import "./assets/css/Questing.css";
import './assets/css/About.css';
import './assets/css/Unity.css';
import './assets/css/Tokenomics.css';
import './assets/css/News.css';
import './assets/css/Forge.css';

import './assets/css/responsive/responsive.css';
import './assets/css/responsive/responsive_about.css';
import './assets/css/responsive/responsive_tokenomics.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { CookiesProvider } from 'react-cookie';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { ThirdwebProvider } from "thirdweb/react";

const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store);

root.render(
  <React.StrictMode>
    <ThirdwebProvider>
      <BrowserRouter>
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </CookiesProvider>
      </BrowserRouter>
    </ThirdwebProvider>
  </React.StrictMode>
);

reportWebVitals();
