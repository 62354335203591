import axios from 'axios';
import { store } from './store';

//https://playgots.xyz/api/
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_ENV === 'dev' ? 'http://localhost:3001/api/' : 'https://portal.playgots.xyz/api/'
})

axiosInstance.interceptors.request.use((config) => {
    // Retrieve the access token from your Redux state and set the Authorization header
    const token = store.getState().user.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

export default axiosInstance;