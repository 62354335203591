import { Fragment, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppBar, Avatar, Box, Container, Divider, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";

import logo from '../assets/img/logo.png';
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Nav, NavDropdown, Navbar, Offcanvas } from "react-bootstrap";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { createBrowserHistory } from "history";

import ConnectThirdweb from "../helpers/ConnectButtons";
import { AutoConnect } from "thirdweb/react";
import { createThirdwebClient } from "thirdweb";
import {
    inAppWallet,
    createWallet,
} from "thirdweb/wallets";
import { avalanche } from "thirdweb/chains";

createBrowserHistory({ basename: process.env.PUBLIC_URL });

const clientId = process.env.REACT_APP_CLIENT_ID;
const client = createThirdwebClient({ clientId: clientId });


const wallets = [
  inAppWallet({
    smartAccount: {
        chain: avalanche,
        sponsorGas: true,
    },
    auth: {
      options: [
        "google",
        "discord",
        "telegram",
        "x",
        "passkey",
        "email",
        "phone",
        "apple",
      ],
    },
  }),
  createWallet("io.metamask"),
  createWallet("com.coinbase.wallet"),
  createWallet("com.trustwallet.app"),
  createWallet("com.binance"),
];

const pages = [
    { id: 1, name: 'PORTAL', link: 'https://portal.playgots.xyz'},
    { id: 2, name: 'TOKENOMICS', link: '/tokenomics' },
    { id: 3, name: 'NEWS', link: '/news'},
    { id: 4, name: 'WHITEPAPER', link: 'https://whitepaper.guardiansofthespark.com/' },
    { id: 5, name: 'ABOUT', link: '/about' },
];


function NavBar({ subpath, setSuccess, setError }) {
    const history = createBrowserHistory()
    const navigator = useNavigate(process.env.PUBLIC_URL);
    const [isTop, changeTop] = useState(false);

    const [show, setShow] = useState(false);
    const [canvas, setC] = useState(false);
    
    const handleCanvasHide = () => setC(false);
    const handleCanvasShow = () => setC(true);

    const toggleDropdown = (e) => {
        if (show) {
            setShow(false);
        } else {
            setShow(true);
        }
    }

    function changeTopValue() {
        if (window.scrollY > 250){
            changeTop(true);
        }else{
            changeTop(false);
        }
    }

    const goTo = (link) => {
        window.open(link, "_blank");
    }

    window.addEventListener('scroll', changeTopValue);

    return (
        <>
            <AutoConnect
                wallets={wallets}
                client={client}
            />
            <AppBar className={history.location.pathname === '/' ? !isTop ? 'fixedAppBar' : '' : ''} color='primary' sx={{ bgcolor: `primary.dark` }}>
                <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            {/* MOBILE */}
                            <Box className='box-menu-mobile' sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'none' } }}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Avatar onClick={() => { navigator('/') }} className="icon-menu-hover" sx={{ width: { xs: '3.5rem', sm: '3.5rem', md: '3.5rem' } }} src={logo} />
                                    {/*<span onClick={() => { navigator('/') }} className="nameLg">GOTS</span>*/}
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{display: 'flex', alignItems: 'stretch', gap: '.2rem'}}>
                                        <ConnectThirdweb label='Log in' />
                                    </div>
                                
                                    <FontAwesomeIcon onClick={handleCanvasShow} className="ps-2" color='white' size='lg' icon={faBars} />
                                </div>
                                <Navbar.Offcanvas scroll="true" data-bs-theme="dark" placement='end' style={{ zIndex: '90000026' }} show={canvas} onHide={handleCanvasHide}>
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title>
                                            <Avatar sx={{ width: '3.5rem' }} src={logo} />
                                            <span className="nameLg">GOTS</span>
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav
                                            className="justify-content-start flex-column  flex-grow-1 ps-3"
                                            activeKey={history.location.pathname}
                                        >
                                            <NavDropdown
                                                title="GAMEPLAY"
                                                show={false}
                                                onClick={toggleDropdown}
                                            >
                                            </NavDropdown>
                                            {show &&
                                                <>
                                                    <Nav.Link className="ps-5" href={subpath + '/forge'}>‣ FORGE</Nav.Link>
                                                    <Nav.Link className="ps-5" href={subpath + '/guardians'}>‣ GUARDIANS</Nav.Link>
                                                    {/*<Nav.Link className="ps-5" href={subpath + '/lore'}>‣ LORE</Nav.Link>*/}
                                                </>
                                            }
                                            {pages && pages.map(page =>
                                                <Nav.Link key={page.id} href={page.name === "WHITEPAPER" ? page.link : subpath + page.link}>{page.name}</Nav.Link>
                                            )}
                                        </Nav>

                                        <div className="icons-offcanvas-menu icons-container-menu">
                                            {/** 
                                            <Divider sx={{ ml: { xs: 0, sm: 1, md: 1, lg: 1 }, mr: { xs: 0, sm: 1, md: 1, lg: 1 } }} orientation="vertical" className="divider-whiteTone" flexItem />
                                            */}
                                            <div className="icon-menu icon-menu-hover" >
                                                <FontAwesomeIcon onClick={() => goTo('https://discord.com/invite/KS7cWmCtgG')} style={{ padding: '.5rem' }} size="sm" icon={faDiscord} />
                                            </div>
                                            <div className="icon-menu icon-menu-hover" >
                                                <FontAwesomeIcon onClick={() => goTo('https://x.com/playgots')} style={{ padding: '.5rem' }} size="sm" icon={faTwitter} />
                                            </div>
                                        </div>

                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                                
                            </Box>

                            {/* DESKTOP */}
                            <div className='logo-typo-flex__nav'>
                                <Avatar onClick={() => { navigator('/') }} className="icon-menu-hover" variant="square" sx={{ width: { xs: '0rem', sm: '0rem', md: '0rem', lg: '4rem' } }} src={logo} />
                                <span className="nameLg2">GOTS</span>
                            </div>
                            
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' }, justifyContent: { md: 'center' } }}>
                                <Nav
                                    className="justify-content-center flex-grow-1 ps-3"
                                    activeKey={history.location.pathname}
                                >
                                    <NavDropdown
                                        title="GAMEPLAY"
                                        show={show}
                                        onClick={toggleDropdown}
                                    >
                                        <NavDropdown.Item href={subpath + '/forge'}>FORGE</NavDropdown.Item>
                                        <NavDropdown.Item href={subpath + '/guardians'}>GUARDIANS</NavDropdown.Item>
                                        
                                        {/*<NavDropdown.Item href={subpath + '/lore'}>LORE</NavDropdown.Item>*/}
                                    </NavDropdown>
                                    {pages && pages.map(page =>
                                        <Nav.Link key={page.id} href={subpath + page.link}>{page.name}</Nav.Link>
                                    )}
                                </Nav>
                            </Box>

                            <Box id="dess1" sx={{ flexGrow: 0, display: { xs: 'none !important', sm: 'none !important', md: 'none !important', lg: 'block !important' } }}>
                                <div className="icons-container-menu">
                                 
                                    <ConnectThirdweb label='Log in' />
                                    {/** 
                                    <ThirdwebBtn setSuccess={setSuccess} setError={setError} />
                                    */}
                                    <Divider sx={{ ml: { xs: 0, sm: 1, md: 1, lg: 1 }, mr: { xs: 0, sm: 1, md: 1, lg: 1 } }} orientation="vertical" className="divider-whiteTone" flexItem />
                                    <div className="icon-menu icon-menu-hover" >
                                        <FontAwesomeIcon style={{ padding: '.6rem .5rem' }} size="sm" onClick={() => goTo('https://discord.com/invite/KS7cWmCtgG')} icon={faDiscord} />
                                    </div>
                                    <div className="icon-menu icon-menu-hover">
                                        <FontAwesomeIcon style={{ padding: '.6rem' }} onClick={() => goTo('https://x.com/playgots')}  size="sm" icon={faTwitter} />
                                    </div>
                                </div>
                            </Box>
                        </Toolbar>
                    
                </Container>
            </AppBar>
            <Toolbar id="back-to-top-anchor" />
        </>
    )
}

export default NavBar;