import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: null,
    user: null,
    jwt: null,
    provider: null,
    gu: null,
    passportInstance: null,
};

const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setGu: (state, action) => {
            state.gu = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setProvider: (state, action) => {
            state.provider = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        logOutUser: (state) => {
            state.token = null;
            state.user = null;
            state.jwt = null;
            state.provider = null;
        },
        setJWT: (state, action) => {
            state.jwt = action.payload;
        },
        initialClaim: (state) => {
            if(state.user){
                state.user.initial_claim = true;
            }
        },
        setCredits:(state, action) => {
            if(state.user){
                state.user.credits = action.payload;
            }
        },
    }
});

export const {setUser, logOutUser, setCredits, initialClaim, setToken, setJWT, setProvider, setGu} = userSlice.actions;

export default userSlice.reducer;