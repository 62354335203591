/* eslint-disable react/prop-types */
import { ConnectButton } from "thirdweb/react";
import {useState} from 'react';
// eslint-disable-next-line no-unused-vars
import { avalanche } from "thirdweb/chains";
import { darkTheme } from "thirdweb/react";
import {useSelector, useDispatch} from 'react-redux';
import { useDisconnect, useActiveWallet } from "thirdweb/react";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import {
  inAppWallet,
  createWallet,
} from "thirdweb/wallets";
import axiosInstance from "../redux/api";
import { setUser, setToken } from "../redux/reducers/userReducer";
import { createThirdwebClient } from "thirdweb";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import QuizIcon from '@mui/icons-material/Quiz';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router-dom";

const clientId = process.env.REACT_APP_CLIENT_ID;
const client = createThirdwebClient({ clientId: clientId });

const wallets = [
  inAppWallet({
    smartAccount: {
      chain: avalanche,
      sponsorGas: true,
    },
    auth: {
      options: [
        "google",
        "discord",
        "telegram",
        "x",
        "passkey",
        "email",
        "phone",
        "apple",
      ],
    },
  }),
  createWallet("io.metamask"),
  createWallet("com.coinbase.wallet"),
  createWallet("com.trustwallet.app"),
  createWallet("com.binance"),
];

const UserMenu = ({goTo, logOut}) => {
    return(
      <Box sx={{ width: '100%', maxWidth: 300, padding: 0}}>
        <List className="list_menu_user" sx={{padding: 0}} >
  
        <ListItem onClick={() => goTo('/user/settings/game-account')}  sx={{padding: 0}} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SportsEsportsIcon />
              </ListItemIcon>
              <ListItemText primary="Game Account" />
            </ListItemButton>
          </ListItem>
          
          <ListItem onClick={() => goTo('/user/airdrop')}  sx={{padding: 0}} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <QuizIcon />
              </ListItemIcon>
              <ListItemText primary="Airdrop" />
            </ListItemButton>
          </ListItem>
  
          <ListItem onClick={() => goTo('/user/settings')}  sx={{padding: 0}} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
  
          <Divider />
  
          <ListItem onClick={() => logOut()} sx={{padding: 0}} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItemButton>
          </ListItem>
        </List>
    </Box>
    )
  }

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 300,
      backgroundColor: '#0E1524',
      borderRadius: 0,
      padding: 0,
      border: '1px solid rgb(86, 139, 236)',
    },
  });

export default function ConnectThirdweb({label, isFull, isHome}){
    const {user, gu} = useSelector(state => state.user);
    const dispatch = useDispatch();
    const { disconnect } = useDisconnect();
    const wallet = useActiveWallet();
    const [userMenu, setUserMenu] = useState(false);

    const handleTooltipClose = () => {
        setUserMenu(false);
    };
    const navigator = useNavigate(process.env.PUBLIC_URL);

    const goTo = (link) => {
        navigator(link);
        handleTooltipClose();
      }

 
    const generatePayload = async ({address}) => {
      try{
        const res = await axiosInstance.get(`auth/tw-login?address=${address}&chainId=${avalanche.id.toString()}`)
  
        return res.data;
      }catch{
        return null;
      }
    }
    const login = async (params) => {
      try{
          let link = 'auth/tw-login';
          if(gu){
            link+='?ref_id='+gu
          }
          const res = await axiosInstance.post(link, { 
              params: params})
          if(res.status === 200){
              dispatch(setUser(res.data?.user))
              
              dispatch(setToken(res.data?.token))
          }
      }catch{
        //
      }
    }
    const logout = async () => {
      disconnect(wallet)
      try{
          await axiosInstance.get('auth/tw-logout')
      }catch{
        //
      }
    }
    

    return(
        <div className="display_flex" style={{width: '100%'}}>
            <div className={`${isHome ? 'tw_btns_home' : ''} tw_btns ${isFull ? 'tw_btns_full' : ''} `}>
                <ConnectButton
                client={client}
                chain={avalanche}
                wallets={wallets}

                connectButton={{ label: label }}
                signInButton={{
                  label: label,
                }}
                theme={darkTheme({
                  colors: { primaryButtonBg: label !== 'Sign in' ? "hsl(123, 38%, 57%)" : "hsl(189, 67%, 58%)" },
                })}
                connectModal={{
                    size: "compact",
                    title: "GOTS Portal",
                    titleIcon:
                    "https://playgots.xyz/static/media/logo.4c3056e0f1daf272f55a.png",
                    showThirdwebBranding: false,
                }}
                auth={{
                isLoggedIn: async (address) => {
                    return user?.walletAddress === address?.toLowerCase();
                },
                doLogin: async (params) => {
                    await login(params);
                },
                getLoginPayload: async ({ address }) =>
                    generatePayload({ address }),
                doLogout: async () => {
                    await logout();
                },
                }}
                />
            </div>
            {user && wallet &&
            <div>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div>
                        <CustomWidthTooltip
                        PopperProps={{
                        disablePortal: true,
                        }}
                        TransitionComponent={Zoom}
                        onClose={handleTooltipClose}
                        open={userMenu}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                        <UserMenu
                        goTo={goTo}
                        logOut={logout}
                        />
                        }
                    >
                        <div className={userMenu ? "icon-menu-user icon-menu-user-selected" : "icon-menu-user"} onClick={() => setUserMenu(!userMenu)} >
                        <img className="create_btn_img" src={user?.profile_picture ? user.profile_picture : '/static/defaultProfilePicture.png'} alt="provider" />
                            {userMenu ? <ExpandLessIcon size="md" className="ms-1" /> : <ExpandMoreIcon size="md" className="ms-1" />}
                        </div>
                    </CustomWidthTooltip>
                    </div>  
                </ClickAwayListener>
            </div>}
        </div>
    )
}