import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ScrollTop from './helpers/ScrollTop.js';
import NavBar from "./layouts/NavBar.js";
import Footer from "./layouts/Footer.js";
import { CssBaseline, Fab, ThemeProvider, createTheme } from "@mui/material";
import { KeyboardArrowUpOutlined } from "@mui/icons-material";
import { useEffect, useState, lazy, Suspense } from "react";
import { createBrowserHistory } from "history";
import { useActiveWallet } from "thirdweb/react";

// Lazy loading components
const HomePage = lazy(() => import('./views/HomePage.js'));
const Tokenomics = lazy(() => import('./views/TokenomicsPage.js'));
const AboutPage = lazy(() => import('./views/AboutPage.js'));
const Forge = lazy(() => import('./components/Forge.jsx'));
const QuestingPage = lazy(() => import('./views/QuestingPage.js'));
const Guardians = lazy(() => import('./views/Guardians.js'));
const UserSettingsPage = lazy(() => import('./views/UserSettingsPage.js'));
const SetAccount = lazy(() => import('./components/SetAccount'));
const RewardsQuesting = lazy(() => import('./views/RewardsQuesting'));
const LogInAdmin = lazy(() => import('./views/admin/LogInAdmin'));
const Dashboard = lazy(() => import('./views/admin/Dashboard'));
const News = lazy(() => import('./views/News'));
const NewsDescription = lazy(() => import('./views/NewsDescription'));
const NewsAdminEdit = lazy(() => import('./views/admin/NewsAdminEdit'));
const Play = lazy(() => import('./views/Play'));

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#234093', dark: '#0d1321' },
    secondary: { main: '#fff3b5' },
    error: { main: '#f44336' },
    warning: { main: '#ffa726' },
    info: { main: '#29b6f6' },
    success: { main: '#66bb6a' }
  },
  typography: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Arial', 'sans-serif'].join(','),
  }
});

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}


function App() {
  const dispatch = useDispatch();
  const history = createBrowserHistory();
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const wallet = useActiveWallet();
  const { user } = useSelector(state => state.user);
  const [showAcc, setShowAcc] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if(user){
      if (user?._id && !user?.username && !user?.roles?.includes('admin')) {
        setShowAcc(true);
      }else{
        setShowAcc(false);
      }
    }
  
  }, [user]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSuccess('');
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') return;
    setError('');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const subpath = '';

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="root">
        <NavBar subpath={subpath} setSuccess={setSuccess} setError={setError} />
        <Suspense fallback={<div className="fallback_home">Loading...</div>}>
          <SetAccount setShowAcc={setShowAcc} showAcc={showAcc} setSuccess={setSuccess} />
          <Snackbar
            open={success !== ''}
            TransitionComponent={SlideTransition}
            autoHideDuration={3000}
            onClose={handleClose}
            message={<Alert icon={<CheckIcon fontSize="inherit" />} severity="success">{success}</Alert>}
          />
          <Snackbar
            open={error !== ''}
            TransitionComponent={SlideTransition}
            autoHideDuration={3000}
            onClose={handleCloseError}
            message={<Alert icon={<CheckIcon fontSize="inherit" />} severity="error">{error}</Alert>}
          />
          <Routes basename={subpath} history={history}>
            <Route path="/" element={<HomePage setSuccess={setSuccess} setError={setError} />} />
            <Route path='/play' element={<Play/>}/>
            <Route path="/tokenomics" element={<Tokenomics />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/:id/:isMostRead?" element={<NewsDescription />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/forge" element={<Forge />} />
            <Route path="/guardians" element={<Guardians />} />
            <Route path="/user/*" element={wallet && user ? (
              <Routes>
                <Route path="/" element={<Navigate to="/user/settings" />} />
                <Route path="/airdrop" element={<QuestingPage />} />
                <Route path="/airdrop/:reward_id/:name" element={<RewardsQuesting />} />
                <Route path="/settings/:path_settings?" element={<UserSettingsPage setSuccess={setSuccess} setError={setError} />} />
              </Routes>
            ) : <Navigate to="/" />} />
            <Route path="/admin/*" element={user?.roles?.includes('admin') ? (
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/news/:id" element={<NewsAdminEdit setSuccess={setSuccess} setError={setError} />} />
                <Route path="/:path_settings?" element={<Dashboard setSuccess={setSuccess} setError={setError} />} />
              </Routes>
            ) : <Navigate to="/admin/login" />} />
            <Route path="/admin/login" element={!user?.roles?.includes('admin') ? <LogInAdmin setSuccess={setSuccess} /> : <Navigate to="/admin" />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
        <ScrollTop>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpOutlined />
          </Fab>
        </ScrollTop>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;